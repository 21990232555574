.ag-cell {
    page-break-inside: avoid !important;
  }
  /* .ag-row-level-0 {
    background-color: rgb(152, 179, 191) !important;
  } */
  
  @media print {
    body {
      overflow: visible;
    }
    .print-button {
        display: none;
      }
  }  

  