.select2-dropdown.select2-dropdown--below{
    width: 100%;
    font-size: 0.8em;  
}
.select2-results__option {
    padding: 3px!important;
    width: 100%!important;
    font-size: 0.7em!important;
}
.select2-container--default .select2-selection--single{
  padding:6px;
  height: 43px;
  width: 100%; 
  font-size: 0.8em;  
  position: relative;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  background-image: -khtml-gradient(linear, left top, left bottom, from(#424242), to(#030303));
  background-image: -moz-linear-gradient(top, #424242, #030303);
  background-image: -ms-linear-gradient(top, #424242, #030303);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #424242), color-stop(100%, #030303));
  background-image: -webkit-linear-gradient(top, #424242, #030303);
  background-image: -o-linear-gradient(top, #424242, #030303);
  background-image: linear-gradient(#424242, #030303);
  width: 40px;
  color: #fff;
  font-size: 0.8em;
  padding: 4px 8px;
    height: 42px;
position: absolute;
top: 0px;
right: 0px;
width: 40px;
}
